#page-room {

    main {
        max-width: 800px;
        margin: 0 auto;

        .title-photo {
            margin: 32px 10px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @media only screen and (min-width: 400px) {
                justify-content: space-between;
                flex-direction: row;
            }

            h1 {
                font-family: 'Pappins', sans-serif;
                font-size: 28px;
                color: #29292e;
                margin: 10px 0px;
            }

            input,
            select {
                height: 50px;
                border-radius: 8px;
                padding: 0 16px;
                background: #29292e;
                border: 1px solid #a8a8b3;
            }
        }

        .container-photo {
            margin-top: 32px;

            .lista-photo {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: space-around;
                align-content: center;
                margin: 10px;

                .no-data {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 24px;
    
                    p {
                        font-family: 'Pappins', sans-serif;
                        font-size: 22px;
                        color: #29292e;
                        margin-bottom: 16px;
                        text-align: justify;
                    }
                }

                .photo-table {
                    background: #fff;
                    border-radius: 8px;
                    padding: 15px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;

                    .photo-item {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-content: center;



                        .flex-col {
                            display: flex;
                            flex-direction: column;
                            margin: 16px;

                            .label {
                                font-weight: 700;
                                color: #29292e;

                                @media only screen and (max-width: 400px) {
                                    font-size: 13px;
                                }
                            }

                            .label-valor {
                                font-weight: 400;
                                color: #29292e;

                                @media only screen and (max-width: 400px) {
                                    font-size: 13px;
                                }
                            }
                        }




                        p {
                            color: #29292e;
                        }

                        .item-ranking {
                            font-weight: 500;

                            @media only screen and (max-width: 400px) {
                                font-size: 13px;
                            }

                            img {
                                width: 18px;
                                height: 18px;
                            }
                        }

                        .head-ranking {
                            font-weight: 700;

                            @media only screen and (max-width: 400px) {
                                font-size: 13px;
                            }
                        }
                    }

                    .photo-botton {
                        display: flex;
                        margin-top: 10px;
                        flex-direction: row-reverse;

                        .flex-botton{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-end;

                            .button{
                                margin: 10px;
                            }

                        }
                    }
                }

            }
        }

        .lista-photo article {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 5px;
            margin-left: 10px;
            margin-right: 10px;

        }

        .lista-photo article a {
            text-decoration: none;
            color: #FFF;
            background-color: brown;
            font-size: 25px;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .conto {
            cursor: pointer;
        }

        .thumb-conto {
            width: 90dvw;
            max-width: 100%;
            max-height: 200px;
            height: 30dvw;
            background-size: cover;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-end;
            flex-direction: row;
            box-shadow: inset 0px -40px 20px 2px rgba(0, 0, 0, 0.9);
        }

        .thumb-h6 {
            color: aliceblue;
            margin: 5px;
            font-size: 20px;
        }

    }
}