.pagination{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .div-bt-pag{
        min-width: 75px;
    }

    .div-h1-pag{
        min-width: 100px;
    }
}