.button {
    height: 50px;
    border-radius: 8px;
    font-weight: 500;
    background: #FD0178;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0;
    transition: filter 0.2s;
    padding: 0 32px;

    &.outlined {
        background: #FD0178;
        border: 1px solid #835afd;
        color: #835afd
    }

    &:not(:disabled):hover {
        filter: brightness(0.9);
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    img {
        margin-right: 8px;
    }
}

.marginbutton {
    margin: 10px;

}

.blocked {
    pointer-events: none;
    opacity: 0.6;
}