#page-room {

    main {
        max-width: 800px;
        margin: 0 auto;

        .photo-info {
            margin: 32px 10px 24px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            position: relative;

            .msg{
                position: absolute;
                top: 8px;
                left: 8px;
                height: 100px;
                display: flex;
                -webkit-box-pack: end;
                justify-content: flex-end;
                -webkit-box-align: center;
                align-items: center;

                @media only screen and (max-width: 500px) {
                    height: 70px;
    
                }
            }

            .photo{
                width: 100%;
            }

            article {
                width: 100%;
                height: 70dvh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
            }

            .thumb-conto-detail {
                width: 100%;
                height: 100%;
                background-size: cover;
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: flex-end;
                flex-direction: row;
            }

            .photo-title {
                margin: 10px 0px;

                h1 {
                    font-family: 'Pappins', sans-serif;
                    font-size: 28px;
                    color: #29292e;
                }
            }

            .photo-player {
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 10px 0;
            }
        }
    }
}