* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: #f0f0f0;
    color: #29292e;
}

body, input, button, select, textarea{
    font: 400 16px 'Roboto', sans-serif;
}


.hide{
    display: none;
}

#page-room {

    main {
        max-width: 800px;
        margin: 0 auto;

        .title {
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @media only screen and (min-width: 400px) {
                justify-content: space-between;
                flex-direction: row;
                margin: 32px 10px 24px;
            }

            h1 {
                font-family: 'Pappins', sans-serif;
                font-size: 28px;
                color: #fff;
                margin: 10px 0px;
            }

            input, select {
                height: 50px;
                border-radius: 8px;
                padding: 0 16px;
                background: #fff;
                border: 1px solid #a8a8b3;
            }
        }
    }
}
