#page-room {
    
    .content-subscription-new {
        max-width: 800px;
        margin: 0 auto;

        .room-voto{
            margin: 10px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span{
                margin-left: 16px;
                background: #61CE70;
                border-radius: 9999px;
                padding: 8px 16px;
                color: #FFF;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .title{
            margin: 32px 10px 24px;
            display: flex;
            align-items: center;
            margin-left: 8px;
            justify-content: space-between;
            height: 50px;

            h1{
                font-family: 'Pappins', sans-serif;
                font-size: 28px;
                color: #fff;
            }
            .ranking-filter-button{
                display: flex;
                align-items: center;
                margin-left: 8px;
                justify-content: space-between;
                padding: 5px;
                width: 200px;

                .filter-select{
                    width: 100%;
                    height: 50px;
                    border-radius: 8px;
                    padding: 0 16px;
                    margin: 5px 0;
                    background: #fff;
                    border: 1px solid #a8a8b3;
                }

            }
        }

        .compra-list{
            margin: 32px 8px 24px 8px;
            display: flex;
            align-items: center;
            margin-left: 8px;
            flex-direction: column;

            .pix-detail{
                display: flex;
                flex-direction: column;
                h3{
                    margin: 16px;
                    color: #fff;
                }
                label{
                    margin: 16px;
                    color: #fff;
                }
                input{
                    height: 50px;
                    border-radius: 8px;
                    padding: 0 16px;
                    margin: 10px 0;
                    background: #fff;
                    border: 1px solid #a8a8b3;
                }
                .separator{
                    font-size: 14px;
                    color: #a8a8b3;
                    margin: 20px 0;
                    display: flex;
                    align-items: center;
            
                    &::before{
                        content: '';
                        flex: 1;
                        height: 1px;
                        background: #a8a8b3;
                        margin-right: 16px;
                    }
            
                    &::after{
                        content: '';
                        flex: 1;
                        height: 1px;
                        background: #a8a8b3;
                        margin-left: 16px;
                    }
                }

            }

            h3{
                margin: 16px;
                color: #fff;
            }
            label{
                margin: 16px;
                color: #fff;
            }
            .separator{
                font-size: 14px;
                color: #a8a8b3;
                margin: 20px 0;
                display: flex;
                align-items: center;
        
                &::before{
                    content: '';
                    flex: 1;
                    height: 1px;
                    background: #a8a8b3;
                    margin-right: 16px;
                }
        
                &::after{
                    content: '';
                    flex: 1;
                    height: 1px;
                    background: #a8a8b3;
                    margin-left: 16px;
                }
            }

            form{
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;
                max-width: 350px;

                .checkbox{
                    background: #fff;
                    border: 1px solid #a8a8b3;
                    height: 20px;
                    width: 20px;
                }

                input{
                    height: 50px;
                    border-radius: 8px;
                    padding: 0 16px;
                    margin: 10px 0;
                    background: #fff;
                    border: 1px solid #a8a8b3;
                }

                select{
                    width: 100%;
                    height: 50px;
                    border-radius: 8px;
                    padding: 0 16px;
                    margin: 10px 0;
                    background: #fff;
                    border: 1px solid #a8a8b3;
                }
    
                button{
                    margin-top: 16px;
                }
    
                button, input {
                    width: 100%;
                }

                
    
            }
        }

        .hidden{
            display:none;
        }

        .show{
            display:flex;
        }


        form{
            textarea{
                width: 100%;
                border: 0;
                padding: 16px;
                border-radius: 8px;
                background: #fefefe;
                box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
                resize: vertical;
                min-height: 130px;
            }
        }
        .form-footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;

            .user-info{
                display: flex;
                align-items: center;

                img{
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }

                span{
                    margin-left: 8px;
                    color: #29292e;
                    font-weight: 500;
                    font-size: 14px;
                }
            }

            .login-info{
                display: flex;
                align-items: center;

                span{
                    font-size: 14px;
                    color: #737388;
                    font-weight: 500;

                    button{
                        background: transparent;
                        border: 0;
                        color: #835AFD;
                        text-decoration: underline;
                        font-size: 14px;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
            }
        }

        .question-list{
            margin-top: 32px;

            .list-flex{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;
                align-content: center;

            }
        }

    }
}